import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Layout, Hero, Panel } from '../components'
import tooth from '../../static/core/tooth-brown.svg'
import { sanitizeFormValue, removeTrailingSlash } from '../utils'

const botfield = uuidv4()
const defaultFormState = {
  [botfield]: '',
  formName: 'contact',
  name: '',
  email: '',
  phone: '',
  existing: '',
  message: '',
}

const ContactPageTemplate = ({ hero: { header }, slug, useBgPattern }) => {
  const [values, setValues] = useState(defaultFormState)
  const [msg, setMsg] = useState('')
  const [isWorking, setIsWorking] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const btnClasses = [
    'submit',
    'btn',
    isWorking ? 'is-loading' : '',
    isSuccess ? 'is-success' : '',
  ]
    .filter((item) => item)
    .join(' ')
  const btnText = isSuccess ? 'Success!' : 'Submit Your Message'

  function handleChange(e) {
    const { name, value } = e.target
    const newState = _.cloneDeep(values)
    newState[name] = sanitizeFormValue(value, name)
    setValues(newState)
  }

  async function handleSubmitAsync() {
    try {
      const response = await fetch('/.netlify/functions/form', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(values),
      })
      const data = await response.json()
      setIsWorking(false)
      setIsSuccess(response.ok)
      setMsg(
        response.ok
          ? 'Thank you! Your message has been received and we will be in touch shortly.'
          : `Error: ${data.error}`,
      )
    } catch (e) {
      console.error(e)
      setIsWorking(false)
      setIsSuccess(false)
      setMsg(`Error: ${e.message}`)
    }
  }

  function handleSubmit(e) {
    e.preventDefault()
    setMsg('')
    if (values[botfield]) {
      return null
    }
    setIsWorking(true)
    handleSubmitAsync()
  }

  const pageClasses = [
    'content-page',
    `page-${
      slug === '/'
        ? 'home'
        : slug
            .split('/')
            .filter((i) => i)
            .join('-')
    }`,
    useBgPattern ? 'tooth-bg' : '',
  ]
    .filter((i) => i)
    .join(' ')

  return (
    <div id="content" className={pageClasses}>
      <Hero header={header} />
      <section id="inner-content" className="cf">
        <Panel panelType="contact" align="center">
          <p>Trying to book an appointment?</p>
          <Link to="/book-online" className="btn">
            Click Here
          </Link>
          <hr style={{ margin: '2rem auto' }} />
          <img src={tooth} style={{ width: '50px' }} alt="happy tooth" />
          <p>Fill out the form below and we’ll get back to you shortly.</p>
          <p>
            (You can also email us at{' '}
            <a href="mailto:office@sfdental.com">office@sfdental.com</a>)
          </p>

          <form id="contact-form" onSubmit={handleSubmit} method="post">
            <div hidden>
              <input
                name={botfield}
                type={'text'}
                aria-label={botfield}
                onChange={handleChange}
                tabIndex="-1"
              />
            </div>
            <input
              type="text"
              id="name"
              name="name"
              value={values.name}
              onChange={handleChange}
              placeholder="Name"
              aria-label="Name"
              required={true}
            />
            <input
              type="email"
              id="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              placeholder="Email"
              aria-label="Email"
              required={true}
            />
            <input
              type="phone"
              id="phone"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              placeholder="Phone (optional)"
              aria-label="Phone (optional)"
              required={true}
            />
            <div className="field">
              Are you an existing patient?
              <input
                type="radio"
                id="existing[yes]"
                name="existing"
                value="yes"
                checked={values.existing === 'yes'}
                onChange={handleChange}
                required={true}
              />
              <label htmlFor="existing[yes]"> Yes</label>
              <input
                type="radio"
                id="existing[no]"
                name="existing"
                value="no"
                checked={values.existing === 'no'}
                onChange={handleChange}
                required={true}
              />
              <label htmlFor="existing[no]"> No</label>
            </div>
            <textarea
              id="message"
              name="message"
              rows={10}
              required={true}
              onChange={handleChange}
              placeholder="Message"
              aria-label="Message"
            />
            <button
              className={btnClasses}
              type="submit"
              form="contact-form"
              disabled={!!isSuccess}
            >
              {btnText}
            </button>
          </form>
          {!!msg && (
            <div className={`notification ${isSuccess ? 'success' : 'error'}`}>
              {msg}
            </div>
          )}
        </Panel>
      </section>
    </div>
  )
}

ContactPageTemplate.propTypes = {
  hero: PropTypes.shape({
    header: PropTypes.string,
  }),
  slug: PropTypes.string.isRequired,
  useBgPattern: PropTypes.bool,
}

const ContactPage = ({ data }) => {
  const {
    frontmatter: { hero, useBgPattern },
    fields: { slug },
  } = data.markdownRemark
  const pageProps = {
    hero,
    slug,
    useBgPattern,
  }
  const { location } = data.site.siteMetadata
  location.slug = removeTrailingSlash(slug)
  return (
    <Layout location={location} data={data}>
      <ContactPageTemplate {...pageProps} />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const pageQuery = graphql`
  query ContactPageTemplate($id: String!) {
    site {
      ...siteMeta
    }
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
        gitAuthorTime
        gitCreatedTime
      }
      frontmatter {
        ...seoFields
        ...heroFields
        templateKey
        useBgPattern
      }
    }
  }
`
